import { Button } from '@radix-ui/themes';
import React from 'react';



const TableRow = ({ role, children }) => (
  <tr className="align-top">
    <td className="w-1/12 font-semibold pt-2 md:pt-4">{role}</td>
    <td className="font-light px-4 pt-2 md:pt-4">{children}</td>
  </tr>
);

const Link = ({ weblink, children }) => (
  <a href={weblink} rel="noopener noreferrer" target="_blank" className="text-gray-500 hover:underline text-gray-600">
    {children}
  </a>
)


function AboutMe() {
  return (
<div className='flex w-full flex-col'>

<div>
  <section className="py-4 px-3 text-left md:px-3 md:py-2">
  <table className="w-full text-base text-gray-400 ">
      <tbody>
      <TableRow role="person">
          <Link weblink="https://drive.google.com/file/d/1of8Nhqj05tQzAudLf14PlMGvRJ431N27/view?usp=drive_link"> 🏛 resume - classic </Link><br/> 
          <Link weblink='https://drive.google.com/file/d/1yRgILLAe_VKnCy5qOGdff4QbBjuU5Map/view?usp=drive_link'> 🌐 resume - modern </Link>
        </TableRow>

        <TableRow role="designer">
          <div className='flex'> <div className='font-[450] text-gray-500'> 🎨 currently interaction designer @ samsung </div> <div className='px-2 font-[550] text-gray-700'> (<Link weblink="https://drive.google.com/file/d/1aF9IWMbKBPGwukjECkJZ9QlZuvN_7qRV/view?usp=drive_link">🔐 view work</Link>) </div></div>
          <Link weblink='https://www.behance.net/cinekai'> 📑 behance portfolio </Link><br/>
          <Link weblink="https://apps.apple.com/in/app/misfits-communities/id6466216367"> 📱 app design</Link> for <Link weblink='https://www.instagram.com/_misfits_official'> misfits communities</Link><br/> 
          <Link weblink='https://drive.google.com/file/d/16IzBMeiSLppuZ87WIBngsV3Fqk2lGrw_/view?usp=drive_link'> 📓 m.des thesis</Link> at iitk (feedforward in design)<br />
          <Link weblink="https://www.behance.net/gallery/124332117/Brand-Creatives"> 🚛 creatives</Link> for <Link weblink='https://www.instagram.com/p/CPps8TwgZ6z/'> awsum.life </Link> 
          

        </TableRow>

        <TableRow role="developer">
          <Link weblink='https://byarunkai.itch.io/keys-and-pills'> 🎮 games</Link> for web + mobile using unity and C# <br/> 
          <Link weblink='https://www.behance.net/gallery/112222769/Instagram-Piano-AR'> 🎭 ar filters</Link> using SparkAR that made me love node based tools <br/> 
          🖥 this website using react, tailwindcss and self-hosted on cloudfare <br/> 
          <Link weblink='https://www.instagram.com/cinesilk/'> 🎞 film dataviz</Link> by extracting dominant color from frames every 5s
        </TableRow>

        <TableRow role="engineer"> 🎓 material science & engineering @ iitk </TableRow>
        <TableRow role="filmmaker"> 🎥 short films on <Link weblink="https://www.youtube.com/cinekai"> youtube </Link> </TableRow>
        <TableRow role="musician"> 
          <Link weblink='https://open.spotify.com/artist/28qEdt9vEaIJ2sLzBFPLvK?si=n7xE0Yn3TFme4cObwwwAhg'> 🎹 spotify </Link>
        </TableRow>

        <TableRow role="writer"> 
          <Link weblink='https://drive.google.com/drive/u/1/folders/1d72tOEEbGVtJL023RcesCq3itzRD_3VS'> 🌊 stories and scripts </Link> <br/>
          the blog below
        </TableRow>
        
      </tbody>
    </table>
</section> </div>
</div>
  );
}

export default AboutMe;
