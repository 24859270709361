import React, { useEffect, useState } from 'react';

const GoogleDocsUnembedder = ({ url }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (url.startsWith('https://docs.google.com/document/d/')) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onload = function () {
        let modifiedContent = xhr.responseText;

        const parser = new DOMParser();
        const doc = parser.parseFromString(modifiedContent, 'text/html');

        const scriptChildren = doc.querySelectorAll('script');
        scriptChildren.forEach(script => {
          script.parentNode.removeChild(script);
        });

        const bannerDiv = doc.getElementById('banners');
        if (bannerDiv) {
          bannerDiv.parentNode.removeChild(bannerDiv);
        }

        const styleChildren = doc.querySelectorAll('style');
        styleChildren.forEach(style => {
          const cssString = style.textContent || '';
          const modifiedCss = cssString.replace(/\}/g, '}.embedded-doc ');
          const lineSpacingFix = modifiedCss.replace(/\line-height/g, 'foo');
          style.textContent = lineSpacingFix;
        });

        const subElements = doc.querySelectorAll('*');
        subElements.forEach(element => {
          element.style.maxWidth = '100%';
          element.style.removeProperty('height');

          if (element.tagName.toLowerCase() !== 'li') {
            element.style.overflow = 'hidden';
          }
        });

        modifiedContent = doc.body.innerHTML;
        setContent(modifiedContent);
      };
      xhr.send();
    }
  }, [url]);

  return (
    <div
      className="embedded-doc"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default GoogleDocsUnembedder;

