import React from 'react';

function Blog() {
  return (
    <header className="text-left pt-12">
    <h1 className="font-semibold pt-8 px-2 text-left md:px-3 text-2xl  md:text-3xl">my thoughts</h1>
  </header>
  
  );
}

export default Blog;
