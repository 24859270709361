import React, {useState} from 'react';

const Link = ({ weblink, children }) => (
  <a href={weblink} rel="noopener noreferrer" target="_blank" className="text-gray-500 hover:underline text-gray-600">
    {children}
  </a>
)
function Header() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); // Example: 768px as the desktop breakpoint

  return (
   <div className='flex-col'>
   <div className="text-left px-2 pt-4 ">

    <h1 className="text-8xl font-bold md:text-8xl">
      arun kai {isDesktop? 'nampoothiri': ''} </h1>
  </div>
  <div className='px-3 text-sm text-gray-400'> <Link weblink='https://www.linkedin.com/in/arun3kn/'> linkedin</Link> | <Link weblink='mailto:arun.who@gmail.com'> email</Link> (darker text = links)</div>

  </div>
  
  );
}

export default Header;
