import React from 'react';
import './App.css';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import BlogHeader from './components/BlogHeader';
import GoogleDocsUnembedder from './components/GoogleDocsUnembedder.js';


function App() {

  const url = 'https://docs.google.com/document/d/e/2PACX-1vSkhwtBNqdAkIQ7W8nBAaum8zcKMo2HMJX22o6VR5GbpgNSOJBpzTSYZbFH66qwsjTvX68f6WaOPwQx/pub';

  return (
    <div className="Home">
      <Header />
      <AboutMe />
      <BlogHeader />
      <div className='lg:w-3/4'> <GoogleDocsUnembedder url={url} /> </div>
      {/* We'll add more components here later */}
    </div>
  );
}


export default App;
